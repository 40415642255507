import React from 'react';

function Footer(props) {

  return (
    <footer id="main-footer" className="main-footer">
    </footer>
  );
}

export default Footer;
